// Base bundle
@use 'bundle';

:root {
  --c-primary: #c60;
  --c-primary-lighter: #e80;
  --c-primary-darker: #a30;
  --c-primary-darkest: #2d0300;
  --c-primary-alt: #220611;

  --f-primary: Verdana, Geneva, Tahoma, sans-serif;
  --logo-subtitle: 'tech one';

  --i-hero: url('../images/backgrounds/orange-bokeh.jpg');
}

@import 'base/patch'